<template>
  <v-progress-circular
    :size="100"
    :width="7"
    color="primary"
    indeterminate
  />

</template>

<script>
export default {
  name: "preloader",
}
</script>

<style scoped>
  .preloader-wrapper{
    margin-top: 2em;
  }
</style>
